<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('grid.ship.editSDW')"
			:modelName="modelName"
			:dialogName="editSDW"
			:onOk="onOkEditSDW"
			:showDialogProp="showDialogEditSDW"
			:widthDialog="700"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<!-- ID -->
					<v-col cols="12">
						<pui-number-field
							:id="`sdw-${modelName}`"
							:label="$t('form.ship.sdw')"
							v-model="data.modalData.sdw"
							toplabel
							:disabled="formDisabled"
						></pui-number-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'shippositioning-modals',
	data() {
		return {
			editSDW: 'editSDW',
			showDialogEditSDW: true
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkEditSDW(modalData) {
			return new Promise(async (resolve) => {
				await this.doUpdateActualShipSDW(modalData);
				resolve(true);
			});
		},
		async doUpdateActualShipSDW(modalData) {
			this.$puiRequests.postRequest(
				'/ship/updateActualShipSDW',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogEditSDW = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogEditSDW = false;
				}
			);
		},
		cancel() {
			this.showDialogEditSDW = false;
		}
	}
};
</script>
