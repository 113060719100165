const editSDW = {
	id: 'editSDW',
	selectionType: 'single',
	label: 'grid.ship.editSDW',
	functionality: 'ACTION_EDIT_SDW',
	checkAvailability: function (registries) {
		return true;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-editSDW-ship-show', row);
	}
};

export default {
	gridactions: [editSDW],
	formactions: []
};
